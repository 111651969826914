import { createGlobalStyle, css } from "styled-components"
import { Theme } from "../theming/theme"
import { textColor } from "../theming/theme-getters"

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  html, body  {
    font-family: 'fieldwork', sans-serif;
    font-weight: 300;
    line-height: 1.5;
  }
`

export const StyledLink = css`
  text-decoration: none;
  color: ${textColor("hover")};

  b,
  strong {
    color: ${textColor("hover")};
  }

  &:hover {
    text-decoration: underline;
  }
`

export const NavLinkStyle = css`
  position: relative;
  text-decoration: none;
  opacity: 0.7;
  transition: opacity 150ms;

  &.active {
    opacity: 1;

    &::after {
      background-color: currentColor;
      transform: translateY(3px) scale(1);
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: transparent;
    transform: scaleX(0.5);
    transition-property: transform, background-color;
    transition-duration: 150ms;
  }

  &:hover {
    opacity: 1;
  }
`

export default GlobalStyle
