/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"

type MetaProps = JSX.IntrinsicElements["meta"]

interface Props {
  readonly title?: string
  readonly description?: string
  readonly lang?: string
  readonly meta?: MetaProps[]
  readonly withSeoTitleTemplate?: boolean
  readonly image?: string
}

const SEO: FunctionComponent<Props> = ({
  description = ``,
  lang = `pl`,
  meta = [],
  title,
  withSeoTitleTemplate = true,
  image = "",
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || site.siteMetadata.title}
      titleTemplate={
        withSeoTitleTemplate && title
          ? `%s | ${site.siteMetadata.title}`
          : undefined
      }
      meta={[
        {
          name: `google-site-verification`,
          content: process.env.GATSBY_GOOGLE_VERIFICATION,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `https:${image}`,
        },
        {
          name: `twitter:image`,
          content: `https:${image}`,
        },
        // @ts-ignore
      ].concat(meta)}
    />
  )
}

export default SEO
