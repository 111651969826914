import React, { FunctionComponent } from "react"
import { Link } from "gatsby"
import textfn from "../../../functions/text"

interface Props {
  children: string
}

const Text: FunctionComponent<Props> = ({ children }) => {
  const content = textfn(children)
  const isBracket = /\[.*?\]/g
  const replacables = content.match(isBracket)
  if (!replacables) {
    return <>{content}</>
  }
  const { jsx } = replacables.reduce(
    (outcome: any, replecable: string) => {
      const [firstPart, tail] = outcome.nextContent.split(replecable)
      const [text, url] = replecable.replace(/[\[\]]/g, "").split("|")

      let urls
      if (url.startsWith("http")) {
        urls = (
          <a
            target="_blank"
            rel="noreferrer noopener nofollow"
            key={url}
            href={url}
          >
            {text}
          </a>
        )
      } else if (url.startsWith("tel") || url.startsWith("mailto")) {
        urls = (
          <a key={url} href={url}>
            {text}
          </a>
        )
      } else {
        urls = (
          <Link key={url} to={url}>
            {text}
          </Link>
        )
      }

      return {
        nextContent: tail,
        jsx: [
          ...outcome.jsx,
          firstPart,
          urls,
          tail.match(isBracket) ? "" : tail,
        ],
      }
    },
    {
      nextContent: content,
      jsx: [],
    } as any
  )
  return <>{jsx}</>
}

export default Text
