import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import text from "../../../functions/text"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import HeadlineContent from "./headline-content.interface"
import Text from "../../base/text/text.component"
import { StyledLink } from "../../global-style.component"

const Container = styled.div<{
  inverted?: boolean
  shady?: boolean
}>`
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  ${({ inverted = false }) =>
    inverted &&
    css`
      color: ${textColor("inverted")};
      background: ${backgroundColor("dark")};
    `}

  ${({ shady = false }) =>
    shady &&
    css`
      background: ${backgroundColor("footer")};
    `}
`

const Title = styled.h2`
  max-width: 48.75rem;
  margin: 0 auto;
  font-weight: 600;
  font-size: 2rem;

  @media (min-width: 48rem) {
    font-size: 2.625rem;
  }
`

const Paragraph = styled.p<{ inverted?: boolean }>`
  margin: 1.625rem 0 0;
  font-weight: 100;
  font-size: 1.25rem;
  white-space: pre-line;
  color: ${({ inverted, theme }) =>
    inverted
      ? textColor("inverted")({ theme })
      : textColor("paragraph")({ theme })};

  @media (min-width: 43.75rem) {
    margin: 1.625rem 0 1.75rem;
    max-width: 50%;
  }

  a {
    ${StyledLink}
  }
`

interface Props extends HeadlineContent {
  readonly inverted?: boolean
  readonly shady?: boolean
  readonly className?: string
}

const SubTitle = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.38;
  margin: 0;
  @media (min-width: 43.75rem) {
    max-width: 50%;
  }
`

const Headline: FunctionComponent<Props> = ({
  title,
  subTitle = "",
  inverted = false,
  shady = false,
  paragraph,
  className,
}) => {
  return (
    <Container inverted={inverted} shady={shady} className={className}>
      {title && <Title data-testid="title">{text(title)}</Title>}
      {subTitle && <SubTitle data-testid="subTitle">{text(subTitle)}</SubTitle>}
      {paragraph && (
        <Paragraph data-testid="paragraph" inverted={inverted}>
          <Text>{paragraph}</Text>
        </Paragraph>
      )}
    </Container>
  )
}

export default Headline
